import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { sendAnalyticsEvent } from 'analytics'
import { ReactComponent as BrowserIcon } from 'assets/imgs/browser.svg'
import { ReactComponent as ExitIcon } from 'assets/imgs/exit.svg'
import { ReactComponent as HowIcon } from 'assets/imgs/how.svg'
import NewBtnIcon from 'assets/imgs/marketCap/newBtn.svg'
import NewBtnHoverIcon from 'assets/imgs/marketCap/newBtn-hover.svg'
import { ReactComponent as NewBtnMIcon } from 'assets/imgs/newBtn-m.svg'
import { useAccountDrawer } from 'components/AccountDrawer'
import { ButtonPrimary } from 'components/Button'
import { StyledNetworkDropDown, StyledSetting, StyledWalletIconIcon } from 'components/Icons/StyledIcon'
import Web3Status from 'components/Web3Status'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useFAUCET } from 'hooks/useContract'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Row } from 'nft/components/Flex'
import { StyledCopyIcon } from 'pages/MarketCap/MarketList'
import { useCallback, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { handlerError } from 'utils/formatError'
import { useDisconnect } from 'wagmi'

import { ReactComponent as LogoDarkIcon } from '../../assets/imgs/Logo_bbeta.svg'
import { ReactComponent as LogoIcon } from '../../assets/imgs/logo_light.svg'
import { ReactComponent as LogoMIcon } from '../../assets/imgs/logo-m.svg'
import Blur from './Blur'
import * as styles from './style.css'
import { NavDropdown } from './NavDropdown2'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

const Logo = styled(LogoIcon)`
  #rogue {
    fill: ${({ theme }) => theme.logoPath};
  }
`

const StyledHowIcon = styled(HowIcon)`
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

const StyledNewBtn = styled(Box)`
  width: 158px;
  height: 36px;
  background-image: url(${NewBtnIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-image: url(${NewBtnHoverIcon});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`

const LogoM = styled(LogoMIcon)``

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
  `};
`

const StyledSettingBox = styled(Box)`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  cursor: pointer;
`

const StyledAccountBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`

const StyledNetworkBox = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 10px 16px;
  gap: 10px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.networkBg};
  border-radius: 8px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 10px 8px;
    gap: 8px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 4px;
    gap: 4px;
  }
`

const StyledNetwork = styled(Row)`
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const StyledAddress = styled(StyledNetwork)`
  text-decoration-line: underline;
  text-decoration-color: ${({ theme }) => theme.textPrimary};
  &:hover {
    text-decoration-color: #1ea7e4;
  }
`

const StyledBrowserIcon = styled(BrowserIcon)`
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

const Navbar = ({ blur }: { blur: boolean }) => {
  const { account } = useActiveChainId()
  const navigate = useNavigate()

  const [isOpenAccount, setIsOpenAccount] = useState(false)
  const toggleOpenAccount = () => {
    setIsOpenAccount(!isOpenAccount)
  }
  const accountInfoNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(accountInfoNode, isOpenAccount ? toggleOpenAccount : undefined)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onDismiss = () => {
    setIsOpen(false)
    setAttemptingTxn(false)
    setTxHash('')
    setTxError('')
  }
  const feact = useFAUCET()

  const handelFaucet = useCallback(async () => {
    if (!feact || !account) return

    setIsOpen(true)
    setAttemptingTxn(true)
    try {
      const response = await feact.claim()
      setAttemptingTxn(false)

      addTransaction(response, {
        type: TransactionType.CLAIM,
        recipient: account,
        uniAmountRaw: '0',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('faucet error', error, handlerError(error))
    }
  }, [account, addTransaction, feact])

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const { disconnect } = useDisconnect()

  const handleLogoIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      // search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const isSmallScreen3 = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const theme = useTheme()

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" justifyContent="space-between" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box
              display="flex"
              alignItems="center"
              gap={isSmallScreen3 ? '8px' : isSmallScreen ? '16px' : '32px'}
              ml={isSmallScreen3 ? 0 : isSmallScreen ? '16px' : '32px'}
            >
              <Box className={styles.logoContainer} onClick={handleLogoIconClick}>
                {isSmallScreen3 ? (
                  <LogoM data-testid="roguex-logo" className={styles.logo} />
                ) : theme.darkMode ? (
                  <LogoDarkIcon data-testid="roguex-logo" className={styles.logo} />
                ) : (
                  <Logo data-testid="roguex-logo" className={styles.logo} />
                )}
              </Box>
              <a
                href="https://app.roguex.io/"
                target="_blank"
                className=""
                style={{ textDecoration: 'none' }}
                rel="noreferrer"
              >
                <Box display="flex" alignItems="center" gap="8px">
                  <StyledBrowserIcon />
                  <ThemedText.TextPrimary fontWeight={400} fontSize={isSmallScreen3 ? 14 : 16}>
                    <Trans>{isSmallScreen3 ? 'APP' : 'RougeX APP'} </Trans>
                  </ThemedText.TextPrimary>
                </Box>
              </a>
            </Box>
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap={isSmallScreen2 ? '8' : '12'}>
              <Web3Status />
              <NavLink to="/how" style={{ textDecoration: 'none' }}>
                {isSmallScreen2 ? (
                  <StyledHowIcon />
                ) : (
                  <ThemedText.TextPrimary fontWeight={700} fontSize={14} style={{ textDecoration: 'underLine' }}>
                    <Trans>How it works?</Trans>
                  </ThemedText.TextPrimary>
                )}
              </NavLink>
              <NavLink to="/create">{isSmallScreen2 ? <NewBtnMIcon /> : <StyledNewBtn />}</NavLink>
              <ConnectButton.Custom>
                {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
                  // console.log('account', account)
                  // Note: If your app doesn't use authentication, you
                  // can remove all 'authenticationStatus' checks
                  const ready = mounted && authenticationStatus !== 'loading'
                  const connected =
                    ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')

                  const handlerOpenChainModal = (e: any) => {
                    e.stopPropagation()
                    openChainModal()
                  }

                  return (
                    <div
                      {...(!ready && {
                        'aria-hidden': true,
                        style: {
                          opacity: 0,
                          pointerEvents: 'none',
                          userSelect: 'none',
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <StyledNetworkBox onClick={openConnectModal} style={{ cursor: 'pointer' }}>
                              <ThemedText.TextPrimary color="backgroundModule2" fontWeight={700}>
                                CONNECT WALLET
                              </ThemedText.TextPrimary>
                            </StyledNetworkBox>
                          )
                        }

                        if (chain.unsupported) {
                          return (
                            <StyledNetworkBox onClick={openChainModal} style={{ cursor: 'pointer' }}>
                              <ThemedText.TextSecondary color="backgroundModule2">
                                Wrong network
                              </ThemedText.TextSecondary>
                            </StyledNetworkBox>
                          )
                        }

                        return (
                          <div style={{ position: 'relative' }} ref={accountInfoNode}>
                            {isSmallScreen3 ? (
                              <StyledNetworkBox
                                onClick={() => {
                                  toggleOpenAccount()
                                }}
                              >
                                <StyledWalletIconIcon />
                                <StyledNetworkDropDown />
                              </StyledNetworkBox>
                            ) : (
                              <StyledNetworkBox
                                onClick={() => {
                                  toggleOpenAccount()
                                }}
                              >
                                <StyledNetwork onClick={handlerOpenChainModal}>
                                  {chain.hasIcon && chain.iconUrl && (
                                    <img
                                      alt={chain.name ?? 'Chain icon'}
                                      src={chain.iconUrl}
                                      style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                      }}
                                    />
                                  )}
                                  {!isSmallScreen2 && (
                                    <div className="flex-1">
                                      <ThemedText.TextPrimary color="backgroundModule2" fontWeight={700}>
                                        <Trans>{chain.name}</Trans>
                                      </ThemedText.TextPrimary>
                                    </div>
                                  )}
                                </StyledNetwork>
                                <StyledNetworkDropDown />
                                <ThemedText.TextSecondary color="backgroundModule2" fontSize={14} fontWeight={700}>
                                  |
                                </ThemedText.TextSecondary>
                                {/* <NavLink to="/profile" style={{ textDecoration: 'none' }}> */}
                                <StyledAddress type="button">
                                  <ThemedText.TextPrimary fontWeight={700} color="backgroundModule2">
                                    <Trans>
                                      {isSmallScreen3
                                        ? shortenAddress(account?.address, 4, 0)
                                        : shortenAddress(account?.address)}
                                    </Trans>
                                  </ThemedText.TextPrimary>
                                </StyledAddress>
                                {/* </NavLink> */}
                              </StyledNetworkBox>
                            )}

                            {isOpenAccount && (
                              <NavDropdown right="0">
                                <Box width="292px" p="8px 16px">
                                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                                    <Trans>Account</Trans>
                                  </ThemedText.TextPrimary>
                                  <StyledAccountBox>
                                    <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
                                      {shortenAddress(account?.address)}
                                    </ThemedText.TextSecondary>
                                    <StyledCopyIcon />
                                  </StyledAccountBox>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="8px"
                                    p="8px 0"
                                    mt="8px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => disconnect()}
                                  >
                                    <ExitIcon />
                                    <ThemedText.TextSecondary fontWeight={400} fontSize={14} color="short">
                                      <Trans>Disconnect</Trans>
                                    </ThemedText.TextSecondary>
                                  </Box>
                                </Box>
                              </NavDropdown>
                            )}
                          </div>
                        )
                      })()}
                    </div>
                  )
                }}
              </ConnectButton.Custom>

              <StyledSettingBox data-testid="settings" onClick={handleWalletDropdownClick}>
                <StyledSetting />
              </StyledSettingBox>
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar

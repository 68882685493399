import '@rainbow-me/rainbowkit/styles.css'

import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { Chain, configureChains, createConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
const SepoliaTest: Chain = {
  id: 421614,
  network: 'ARBITRUM_SEPOLIA Testnet',
  name: 'ARBITRUM_SEPOLIA Testnet',
  nativeCurrency: {
    name: 'ARBITRUM_SEPOLIA Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://arbitrum-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
    public: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
  },
  testnet: true,
}

const BLAST_MAIN: Chain = {
  id: 81457,
  network: 'BLAST ',
  name: 'BLAST ',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast.infura.io/v3'],
    },
    default: {
      http: ['https://rpc.blast.io'],
    },
    public: {
      http: ['https://rpc.blast.io'],
    },
  },
  testnet: true,
  hasIcon: true,
  iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

const BLAST_TEST: Chain = {
  id: 168587773,
  network: 'BLAST Testnet',
  name: 'BLAST Testnet',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia.blast.io'],
    },
    public: {
      http: ['https://sepolia.blast.io'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [BLAST_MAIN],
  [alchemyProvider({ apiKey: 'bBUosx2q8Pm3dJKBkoxKxPoW5JwPNFDy' }), publicProvider()]
  // [infuraProvider({ apiKey: 'f7d803169aa248f28a510d0d1889951c' })]
)

const { connectors } = getDefaultWallets({
  appName: 'ROGUEX',
  projectId: 'd9e02d8e4a4bb8fe574b63430fa1cf34',
  chains,
})

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
})

export const chain = chains
